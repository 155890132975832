.title {
  margin-bottom: 16px;
  font: var(--Heading-XL-Bold);
  text-align: center;
}

.note {
  margin-bottom: 32px;
  font: var(--Heading-M-SemiBold);
  color: #6D7582;
}

.form {
  width: 100%;

  input {
    background-color: var(--invert-primary) !important;
  }
}

.button {
  align-self: flex-start;
  margin-bottom: 30px;
}

.timer {
  margin-top: 16px;
}

.button {
  width: 100%;
  margin-top: 24px;
  padding: 16px 14px;

  background: var(--invert-primary);
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  text-transform: uppercase;

  span {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.4;
  }
}
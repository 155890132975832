.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);

  animation: modalShow 0.5s ease-in 0s 1 normal forwards;
}

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 13%;
  max-width: 768px;
  margin: 0 auto;
  background: var(--black100);
  z-index: 100;

  animation: slide-up 0.5s ease-in 0s 1 reverse forwards;
}

.modalHead {
  display: flex;
  justify-content: right;
  padding: 26px 16px;

  button {
    border: 0;
    padding: 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(900px);
  }
}

@keyframes modalShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

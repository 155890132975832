.container {
  position: relative;
  background: url('../../assets/images/backgroundImg.png') center no-repeat;
  background-size: 170%;
}

//user profile
.profileContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  min-height: 90px;
}

.avatar {
  max-width: 42px;
}

.profileInfo {
  display: flex;
  flex-direction: column;
}
.name {
  font: var(--Paragraph-XS-Regular);
  color: var(--black700);
}
.phone {
  font: var(--Heading-M-Bold);
}

//balance
.balanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  border-radius: 14px;
  margin: 0 16px;
  padding: 0 16px;
  background: var(--gradient1);
  color: var(--invert-primary);
}

.left {
  font: var(--Paragraph-M-SemiBold);
}
.right {
  font: var(--Balance-XL-ExtraBold);
}

.button {
  margin-left: auto;
  border: 1px solid var(--black300);
  border-radius: 8px;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    max-width: 72px;
  }
}

.image {
  position: relative;
  width: 70px;

  .success {
    position: absolute;
    background: url("../../../assets/images/ServicesIcons/iconSuccess.svg");
    background-size: 100%;
    height: 29px;
    width: 29px;
    bottom: -5px;
    right: -10px;
  }
}

.titleContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  margin-top: 22px;
}

.titleBuy {
  font: var(--Heading-L-ExtraBold);
  color: var(--primary600);
}
.titleSend{
  font: var(--Heading-L-ExtraBold);
  color: var(--primary);
}

.secondTitle {
  display: flex;
  align-items: center;
  gap: 9px;

  div {
    font: var(--Heading-M-Bold);
    color: var(--black500);
  }

  img {
    flex: 1;
  }
}

.optionInfo {
  margin-top: 16px;
  font: var(--Heading-M-SemiBold);
  color: var(--black500);
}

@mixin shrinkLabel {
  top: 5px !important;
  font-size: 12px !important;
}

.group {
  position: relative !important;
  margin-bottom: 10px !important;

  .formInput {
    appearance: none;
    background-color: var(--black100);
    color: var(--primary) !important;
    font: var(--Paragraph-M-SemiBold) !important;
    display: block !important;
    width: 100% !important;
    min-width: 250px;
    padding: 35px 5px 8px 14px !important;
    border-radius: 10px !important;
    border: 1px solid var(--black700) !important;
    caret-color: var(--primary800) !important;

    &:focus {
      outline: none !important;
      border: 1px solid var(--black500) !important;
    }

    &:focus ~ .inputLabel {
      @include shrinkLabel();
    }
  }

  .inputLabel {
    appearance: none;
    font: var(--Paragraph-M-SemiBold) !important;
    color: var(--black600) !important;
    position: absolute !important;
    pointer-events: none !important;
    left: 14px !important;
    top: 22px !important;
    transition: 300ms ease all !important;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
.error {
  width: 100%;
  min-width: 250px;
  margin-top: 5px;
  padding-left: 16px;
  font: var(--Caption-S-Regular);
  text-align: left;
  color: red;
  box-sizing: border-box;
}

.fieldset {
  margin-top: 40px;
  padding: 30px 0;
  border: none;
  border-top: 2px solid var(--black400);

  legend {
    padding: 0 10px;
    text-align: center;
    color: var(--black400);
  }
}

.image {
  margin: auto 0 60px;
} 

.button {
  width: 100%;
  margin-top: 10px;
  padding: 16px 14px;

  background: #35D581;
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  color: var(--invert-primary);

  span {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.button-exit {
  margin: auto 0 20px;
  padding: 0;
  text-decoration: underline;
  background-color: transparent;
  font: var(--Caption-L-Medium);
  color: var(--secondary);
  cursor: pointer;
}

.detailsList {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 24px;
  margin-top: 32px;

  height: 100%;
  padding: 0 16px 0 16px;
  overflow: auto;
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.name {
  font: var(--Paragraph-M-SemiBold);
  color: var(--black500);
}

.value {
  font: var(--Heading-M-SemiBold);
  color: var(--black900);
  word-wrap: break-word;
}

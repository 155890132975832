:root {
  --main-font: 'Inter', sans-serif;

  //Colors
  --primary: #2C2C2C;
  --invert-primary: #FFFFFF;
  --secondary: #1890FF;

  //Primary
  --primary50: #E8FAF1;
  --primary100: #BBF1D5;
  --primary200: #8EE7B9;
  --primary300: #61DE9D;
  --primary400: #35D581;
  --primary500: #34CC81;
  --primary600: #27C974;
  --primary700: #22A76E;
  --primary800: #1B915F;
  --primary900: #187852;

  //Neurtal
  --neutral50: #EDEDEF;
  --neutral100: #DEE1E6;
  --neutral200: #CCD2DD;
  --neutral300: #A7B0C0;
  --neutral600: #6D7582;

  //Black
  --black100: #F2F2F2;
  --black200: #DDD;
  --black300: #C4C4C4;
  --black400: #B2B2B2;
  --black500: #919191;
  --black600: #4F4F4F;
  --black700: #3B3B3B;
  --black800: #303030;
  --black900: #202020;

    //Gradient
  --gradient1: linear-gradient(279.5deg, #20BF6B 0%, #39D683 100%);

  //Fonts
  /*--Balance-XL-ExtraBold: 800 27px / 32px var(--main-font);*/

  --Heading-XL-Bold: 700 30px / 36px var(--main-font);
  --Heading-XL-ExtraBold: 800 24px / 28px var(--main-font);
  --Heading-L-ExtraBold: 800 20px / 24px var(--main-font);
  --Heading-L-Bold: 700 18px / 24px var(--main-font);
  --Heading-M-ExtraBold: 800 16px / 18px var(--main-font);
  --Heading-M-Bold: 700 16px / 18px var(--main-font);
  --Heading-M-SemiBold: 600 16px / 20px var(--main-font);

  --Paragraph-L-Regular: 400 16px / 18px var(--main-font);
  --Paragraph-M-SemiBold: 600 15px / 22px var(--main-font);
  --Paragraph-S-Bold: 700 14px / 20px var(--main-font);
  --Paragraph-XS-SemiBold: 600 13px / 24px var(--main-font);
  --Paragraph-XS-Regular: 400 13px / 24px var(--main-font);

  --Caption-XL-Regular: 400 32px / 36px var(--main-font);
  --Caption-L-Medium: 500 14px / 16px var(--main-font);
  --Caption-M-Bold: 700 12px / 15px var(--main-font);
  --Caption-M-Medium: 500 12px / 15px var(--main-font);
  --Caption-S-Regular: 400 12px / 15px var(--main-font);
}




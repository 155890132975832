// @keyframes shake {
//   0% {
//     transform: translate(-50%, -500%);
//   }

//   70% {
//     transform: translate(-50%, -40%);
//   }

//   90% {
//     transform: translate(-50%, -55%);
//   }

//   100% {
//     transform: translate(-50%, -50%);
//   }
// }

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  padding: 40px 16px;
}

.header {
  margin: 20px 0 auto;
  font: var(--Heading-L-Bold);
  color: var(--black800);
}

.title {
  margin-bottom: 10px;
  font: var(--Caption-S-Regular);
  color: var(--black500);
}

// .phone {
//   font: var(--Heading-L-Bold);
//   color: var(--primary);
// }

.indicators-wrapper {
  display: flex;
  margin: 24px 0;
}

.indicator {
  width: 10px;
  height: 10px;
  margin: 0 12px;
  border-radius: 2px;
  background-color: var(--black400);

  &--active {
    background-color: var(--primary400);
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  max-width: 309px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 8px 14px;
}

.button-symbol {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--primary400);
  font: var(--Caption-XL-Regular);
  color: var(--invert-primary);
  cursor: pointer;
}

.button-text {
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.button-exit {
  margin: auto 0 20px;
  padding: 0;
  text-decoration: underline;
  background-color: transparent;
  font: var(--Caption-L-Medium);
  color: var(--secondary);
  cursor: pointer;
}

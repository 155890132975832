.login {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 44px;
    padding: 8px 24px;
    background-color: var(--invert-primary);
    box-sizing: border-box;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    max-width: 374px;
    height: calc(100% - 44px);
    margin-top: 44px;
    padding: 24px;
    box-sizing: border-box;
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 16px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;

  background: var(--invert-primary);
  border-radius: 16px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
}

.title {
  font: var(--Heading-XL-ExtraBold);
  color: var(--black900);
  margin-bottom: 16px;
}

.description {
  font: var(--Paragraph-XS-Regular);
  color: var(--black900);
  max-width: 340px;
}

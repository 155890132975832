.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
}

.selectors {
    display: flex;
    margin-bottom: 50px;
}

.selector {
    margin: 0 20px;

    label {
        margin-right: 15px;
        padding-top: 2px;
      }
      
      input {
        appearance: none;
      
        border-radius: 50%;
        width: 16px;
        height: 16px;
      
        border: 2px solid var(--black500);
        transition: 0.2s all linear;
        margin-right: 5px;
      
        position: relative;
        top: 4px;
      }
      
      input:checked {
        border: 6px solid var(--primary600);
      }
      
      button,
      legend {
        color: white;
        background-color: black;
        padding: 5px 10px;
        border-radius: 0;
        border: 0;
        font-size: 14px;
      }
      
      button:hover,
      button:focus {
        color: var(--black500);
      }
      
      button:active {
        background-color: white;
        color: black;
        outline: 1px solid black;
      }
}
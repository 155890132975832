.listContainer {
  // height: 100%;
  padding: 0 16px 0 16px;
  // overflow: auto;

  &::-webkit-scrollbar,
  & *::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

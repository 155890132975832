@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import "assets/styles/vars";

body {
  margin: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);
  background-color: var(--neutral50);
}

h1,h2,h3,button,input,p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button,input {
  border: none;
}

.app-container {
  display: flex;
  flex-direction: column;
  max-width: 768px;
  // min-height: 650px;
  margin: 0 auto;
  height: 100vh;
  // overflow: hidden;
}

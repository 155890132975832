.headNav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  min-height: 90px;
}

.navBack {
  position: absolute;
  border: none;
  background: none;
  padding: 0;
  left: 16px;

  img {
    width: 20px;
  }
}

.title {
  font: var(--Heading-L-ExtraBold);
}

// .image {
//   margin-bottom: 60px;
// }

.title {
  margin-bottom: 32px;
  font: var(--Heading-XL-Bold);
  text-align: center;
}

.form {
  width: 100%;

  input {
    background-color: var(--invert-primary) !important;
  }
}

.confirm {
  margin-top: 16px;
  padding: 24px 16px;
  border-radius: 12px;
  background-color: var(--invert-primary);

  &__title {
    font: var(--Heading-M-SemiBold);
    color: #6D7582;
  }

  &__block {
    display: flex;
    margin-top: 10px;
  }

  &__field {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      padding: 2px;
      border: 1px solid #adb5bd;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__field:checked + &__label::before {
    border-color: #35D581;
    background-color: #35D581;
    background-image: url("../../assets/images/check.svg");
  }

  &__field:not(:disabled):not(:checked) + &__label:hover::before {
    border-color: #6eac8c;
  }

  &__field:not(:disabled):active + &__label::before {
    background-color: #6eac8c;
    border-color: #6eac8c;
  }

  &__field:focus + &__label::before {
    box-shadow: 0 0 4px 1px #9ba29e;
  }

  &__field:focus:not(:checked) + &__label::before {
    border-color: #35D581;
  }

  &__field:disabled + &__label::before {
    background-color: #e9ecef;
  }

  &__link {
    font: var(--Heading-M-SemiBold);
    color: #35D581;
    text-decoration: none;
  }
}

.button {
  width: 100%;
  margin-top: 24px;
  padding: 16px 14px;

  background: #35D581;
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  color: var(--invert-primary);
  text-transform: uppercase;

  span {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.4;
  }
}
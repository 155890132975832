.container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 24px 16px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  width: 100%;
  height: 101px;
  box-shadow: 0 6px 15px rgba(79, 79, 79, 0.12);
  border: none;
  border-radius: 10px;
  background: var(--invert-primary);
  color: var(--primary);
  font: var(--Paragraph-XS-Regular);

  &:active {
    background: var(--neutral50);
  }
}

.button {
  width: 100%;
  padding: 16px 14px;

  background: #35D581;
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  color: var(--invert-primary);

  span {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.input {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.inputWrapper {
  display: flex;
  margin-bottom: 20px;
}

.label {
  display: flex;
  width: 100%;
  
  img {
    margin-right: 20px;
  }
}

.info {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  padding: 4px;
  border: 2px solid var(--black300);
  border-radius: 8px;
}

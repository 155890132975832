.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  text-decoration: none;
}

.left {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 56px;
  }
}

.leftInfo {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.right {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 9px;
}

.transaction {
  font: var(--Heading-M-Bold);
  color: var(--primary);
}

.bottomGray {
  font: var(--Caption-M-Bold);
  color: var(--black400);
}

.valueBlack {
  font: var(--Heading-M-ExtraBold);
  color: var(--primary);
}

.valueGreen {
  font: var(--Heading-M-ExtraBold);
  color: var(--primary600);
}

.loader {
  position: absolute;
  background: url('../../assets/images/ServicesIcons/loaderIcon.png');
  background-size: 100%;
  height: 23px;
  width: 23px;
  bottom: -10px;
  left: 36px;
  animation: rotate 2s infinite linear;
}

@media (max-width: 640px) {
  .valueBlack {
    font: var(--Caption-M-Bold);
    color: var(--primary);
  }

  .valueGreen {
    font: var(--Caption-M-Bold);
    color: var(--primary600);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80%;
}

.description {
  font: var(--Paragraph-XS-Regular);
  color: var(--black500);
  max-width: 340px;
  margin-top: 10px;
}

.button {
  width: 50%;
  margin-top: auto;
  padding: 16px 14px;

  background: var(--invert-primary);
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  color: var(--primary);
  text-transform: uppercase;

  span {
    margin-right: 6px;
  }
}

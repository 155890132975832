.button {
  width: 100%;
  padding: 16px 14px;

  background: var(--primary400);
  border-radius: 10px;

  font: var(--Heading-M-Bold);
  color: var(--invert-primary);
  text-transform: uppercase;

  span {
    margin-right: 6px;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  text-align: start;
  min-width: 180px;
  width: 100%;
  height: 100px;
  padding: 0 16px;
  border-radius: 14px;
  border: none;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.08);
  background-color: var(--invert-primary);
  opacity: 0.7;

  &:active {
    background: var(--neutral50);
  }
}

.contActive {
  opacity: 1;
  background-color: var(--invert-primary);
  border: 1px solid var(--primary100);
}

.ImgCurrency {
  position: absolute;
  right: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 470px) {
    gap: 16px;
  }
}

.text {
  font: var(--Paragraph-M-SemiBold);
  color: var(--neutral600);
}

.value {
  font: var(--Heading-L-ExtraBold);

}

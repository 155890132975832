.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  background: url("../../../assets/images/LoaderSuspense.png");
  background-size: 100%;
  height: 40px;
  width: 40px;

  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}}
